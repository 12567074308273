<template>
  <div class="detail-div">
    <CRow>
      <CModal
          :title="
        submitType === 'Update'
          ? $lang.profileRequest.crud.view_update
          : $lang.profileRequest.crud.create
      "
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
            err_msg
          }}
        </CAlert>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <div class="card">
              <div class="card-body">
                <!--                <div class="bd-example">-->
                <!--                  <dl class="row" v-for="(request, index) in responseData">-->
                <!--                    <dt class="col-sm-3">{{ request.label }}</dt>-->
                <!--                    <dd class="col-sm-9" v-if="request.label">-->
                <!--                      {{ request.value }}-->
                <!--                    </dd>-->
                <!--                  </dl>-->
                <!--                </div>-->

                <CRow>
                  <CCol sm="6" md="4" lg="12">
                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                      <th>Field</th>
                      <th>Old Value</th>
                      <th>New Value</th>
                      <th>Actions</th>
                      </thead>
                      <tbody>

                      <tr

                          v-for="(request, index) in responseData"
                          :key="request.field"
                          class="doc-box">
                        <td>{{ request.label }}</td>
                        <td>{{ request.oldValue }}</td>
                        <td>{{ request.value }}</td>
                        <td>
                          <CButton color="info" size="sm" v-if="request.status!=='Verified'"
                                   @click="updateValue(request.field,request.id,self.responseUser.profileId,self.responseUser.userId)">
                            Verify
                          </CButton>
                          <CButton color="success" size="sm" v-if="request.status==='Verified'">
                            Verified
                          </CButton>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </CCol>
                </CRow>

                <input
                    type="hidden"
                    class="form-control"
                    v-model="profileRequest.userId"
                />
                <input type="hidden" v-model="profileRequest.requestId">

                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.profileRequest.form.status }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="statusOptions"
                      v-model="profileRequest.status"
                  ></v-select>
                </div>
              </div>
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
          </CCardFooter>
        </CForm>
        <template #footer style="display: none">
          <CButton @click="darkModal = false" color="danger" style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>

      <CCol col="12">
        <div class="row">
          <CCol sm="12" class="align-self-center">
            <div class="text-sm-right mt-3 mt-sm-0">
              <CButtonGroup class="file-export">
                <CButton color="secondary" disabled>
                  {{ $lang.buttons.export.title }}
                </CButton>
                <CButton
                    color="danger"
                    v-c-tooltip="$lang.buttons.export.pdf"
                    v-on:click="
                      exports(
                        self,
                        'pdf',
                        module,
                        'Profile Update Requests',
                        self.editId
                      )
                    "
                >
                  <i class="fas fa-file-pdf"></i>
                </CButton>
                <VueJsonToCsv
                    class="btn btn-success"
                    v-c-tooltip="$lang.buttons.export.excel"
                    :json-data="json_data"
                    :labels="json_label"
                    :csv-title="json_title"
                >
                  <i class="fas fa-file-excel"></i>
                </VueJsonToCsv>
              </CButtonGroup>
            </div>
          </CCol>
        </div>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CAlert
            color="success"
            closeButton
            :show.sync="dismissCountDownS"
            fade
        >{{ alertMessage }}
        </CAlert
        >

        <v-server-table
            :columns="columns"
            :url="listUrl"
            :options="options"
            ref="myTable"
            @loaded="
              exports(
                self,
                'Excel',
                module,
                'Profile Update Requests',
                self.editId
              )
            "
        >
          <template #addedRequest="data">
            {{ data.row.addedRequest }}
          </template>
          <template #status="data">
            <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
          </template>
          <template #actions="data">
            <CButton
                color="primary"
                v-c-tooltip="$lang.buttons.general.crud.viewRequest"
                v-on:click="
                  viewProfileUpdateRequestTableRow(data.row.requestId)
                "
                v-if="checkPermission('view-profile-verification')"
            >
              <i class="fas fa-eye"></i>
            </CButton>
          </template>
        </v-server-table>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {profileUpdateRequest, verificationStatus} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "/src/components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "ProfileUpdateRequest",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      responseData: [],
      statusOptions: [],
      activePage: 1,
      listUrl: "/users/profile/update-request/list/",
      module: profileUpdateRequest,
      moduleVerificationStatus: verificationStatus,
      profileRequest: {
        status: "",
        userId: "",
        companyId: "",
        requestId: "",
        requestedId: "",
      },
      columns: ["name", "status", "addedRequest", "actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.profileRequest.table.name,
          status: this.$lang.profileRequest.table.status,
          addedRequest: this.$lang.profileRequest.table.addedRequest
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },

  validations: {
    profileRequest: {
      status: {
        required,
      },
    },
  },
  created() {
    let self = this;
    self.editId = this.$route.params.id;
    self.listUrl = self.listUrl + self.editId;
  },
  mounted() {
    let self = this;

    self.editId = this.$route.params.id;

    // self.listUrl = this.listUrl + self.editId;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", () => {
      // this.setAlert(true);
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");

    axios
        .get(this.listUrlApi(this.moduleVerificationStatus))
        .then((response) => {
          self.subIndustryOptions = [];
          response.data.data.map(function (value, key) {
            if (
                value.label === "Pending" ||
                value.label === "Verified" ||
                value.label === "Blocked"
            ) {
              self.statusOptions.push({value: value.label, label: value.label});
            }
          });
        });

    self.profileRequest.status = {
      value: "pending",
      label: "Pending",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {

    updateValue(field, id, companyId, userId) {
      let self = this;
      const postData = {
        id: id,
        field: field,
        companyId: companyId,
        userId: userId,
        updateSpecificValue: "true",
      };
      axios
          .post(this.profileupdateRequestUrlweb(this.module), postData)
          .then(function (response) {
            if (response.data.message) {
              self.largeModal = true;
              // self.alertMessage = response.data.message;

              if (response.data.status === 200) {
                axios
                    .get(self.viewUrlApi(self.module, self.editId), self.model)
                    .then(function (response) {
                      if (response) {
                        self.responseData = response.data.data.request;
                        self.responseUser = response.data.data.user;
                        self.profileRequest.status = self.responseUser.status;
                        self.profileRequest.userId = self.responseUser.userId;
                        self.profileRequest.companyId = self.responseUser.profileId;
                      } else {
                        self.data = [];
                      }
                    });
              }

            } else {
              self.err_msg = response.data.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
            self.submitted = false; //Enable Button
          });
    },

    onSubmit() {
      let self = this;
      this.$v.profileRequest.$touch();
      if (this.$v.profileRequest.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";

        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              status: self.profileRequest.status.value,
              userId: self.profileRequest.userId,
              requestedId: self.profileRequest.requestedId,
              byAdmin: "true",
            };
            axios
                .post(this.profileupdateRequestUrlweb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.status === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },

    viewProfileUpdateRequestTableRow(item) {
      let self = this;
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              self.responseData = response.data.data.request;
              self.responseUser = response.data.data.user;
              self.profileRequest.status = {
                'value': self.responseUser.status,
                'label': self.responseUser.status,
              }
              self.profileRequest.status = self.responseUser.status;
              self.profileRequest.userId = self.responseUser.userId;
              self.profileRequest.companyId = self.responseUser.profileId;
              self.profileRequest.requestedId = self.responseUser.requestedId;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    setAlert(value) {
      this.showAlert = value;
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
