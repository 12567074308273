<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.profile.child_users }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Profiles')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.country
                    }}</label>
                  <v-select
                      :options="countryOptions"
                      v-model="profile.country"
                      @option:selected="loadState"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.state
                    }}</label>
                  <v-select
                      :options="StateOptions"
                      v-model="profile.state"
                      @option:selected="loadCity"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.city
                    }}</label>
                  <v-select :options="cityOptions" v-model="profile.city">
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.user
                    }}</label>
                  <v-select
                      :options="userOptions"
                      v-model="profile.user"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >

          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Profiles')"
          >
            <template #name="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.name"
                  :length="20"
              />
            </template>
            <template #JobTitleStatus="data">
              <CBadge :color="statusBudget(data.row.JobTitleStatus)">{{ data.row.JobTitleStatus }}</CBadge>
            </template>
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #actions="data">
              <CButton color="primary" v-c-tooltip="$lang.buttons.general.crud.view"
                       v-on:click="viewTableRow(data.row.userId)">
                <i class="fas fa-user"></i>
              </CButton>
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view_resource"
                  v-on:click="viewAllTableRow(data.row.userId)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.suspend"
                  v-on:click="deactiveRow(data.row.userId,data.row.role)" v-if="data.row.active === 1"
              ><i class="fas fa-user-slash"></i>

              </CButton>
              <CButton
                  color="warning"
                  v-c-tooltip="$lang.buttons.general.active"
                  v-on:click="deactiveRow(data.row.userId,data.row.role)" v-if="data.row.active === 0"
              >
                <i class="fas fa-user"></i>
              </CButton>
              <!-- <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete" v-on:click="deletePopup(data.row.id)" v-if='checkPermission("delete-profile")' >
                                <i class="fas fa-trash"></i>
                            </CButton> -->
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  city,
  country,
  companyChildUsers,
  state,
  users,
  companyUserLists,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import BeforeFilter from "/src/views/BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "ChildUsers",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  props: ['companyId'],

  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false /* for filter toggle*/,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      userOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      listUrl: "/users/child/list/",
      module: companyChildUsers,
      companyUserLists: companyUserLists,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      profile: {
        name: "",
        isVerified: "",
      },
      columns: ["role", "name", "title", "JobTitleStatus", "country", "state", "city", "locationChangeCount","status", "actions"],
      data: [],
      options: {
        headings: {
          role: this.$lang.profile.table.role,
          name: this.$lang.profile.table.name,
          title: this.$lang.profile.table.title,
          JobTitleStatus: this.$lang.profile.table.JobTitleStatus,
          country: this.$lang.profile.table.country,
          state: this.$lang.profile.table.state,
          city: this.$lang.profile.table.city,
          locationChangeCount: this.$lang.profile.table.locationChangeCount,
          status: this.$lang.profile.table.status,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = self.listUrl + this.companyId;
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });

    axios.get(this.childUsersUrlApi(this.companyUserLists, this.companyId))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.userOptions.push({value: value.id, label: value.name});
          });
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    //Filer
    loadState() {
      let self = this;
      const postData = {countryId: self.profile.country.value};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.profile.state.value};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },

    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                  // self.$router.go()
                }
              });
        }
      });
    },
    viewAllTableRow(item) {
      this.$router.push({path: `/profile/all/${item}`});
    },
    deactiveRow(item,role) {
      this.$swal({
        title: this.$lang.common.deactive_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.deactive_swal.yes_delete,
        cancelButtonText: this.$lang.common.deactive_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          if (role == 'Admin') {
            axios.post('users/child/deactive/' + item,{
              creater : true
            }).then((response) => {
              if (response.data.code === 200) {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.dismissCountDownS = 10;
              }
            });
          } else {
            axios.post('users/child/deactive/' + item).then((response) => {
              if (response.data.code === 200) {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.dismissCountDownS = 10;
              }
            });
          }
        }
      });
    },
    editTableRow(item) {
      this.$router.push({path: `profile/edit/${item}`});
    },
    viewTableRow(item) {
      let self = this;
      if (item === self.$route.params.id) {
        this.$router.go(this.$router.currentRoute)
      }
      this.$router.push({path: `/profile/view/${item}`});
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    applyFilter() {
      let user = "";
      let country = "";
      let state = "";
      let city = "";

      if (this.profile.user != null) {
        user = this.profile.user.value;
      }
      if (this.profile.country != null) {
        country = this.profile.country.value;
      }
      if (this.profile.state != null) {
        state = this.profile.state.value;
      }
      if (this.profile.city != null) {
        city = this.profile.city.value;
      }

      let reqObj = {
        user: user,
        country: country,
        state: state,
        city: city,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },

    resetFilter() {
      this.profile.user = null;
      this.profile.country = null;
      this.profile.state = null;
      this.profile.city = null;
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
